/* stylelint-disable selector-max-type */
button {
    appearance: none;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    cursor: pointer;
    font-family: inherit;
    font-size: inherit;
    font-weight: normal;
    margin: 0;
    padding: 0;
}

button:hover {
    cursor: pointer;
}

input,
textarea,
select {
    appearance: none;
    border: 0;
    border-radius: 0;
    margin: 0;
}

a,
a:active,
a:focus,
a:hover {
    text-decoration: none;
}

// for when phone numbers are turned into links and don't have specific classes on them
a {
    color: inherit;
}

// different than default reset
input[type='search'] {
    box-sizing: border-box;
}

// These styles get passed to @sanity/block-content-to-react which only accepts className for styling
.brand-landing-page-serializer {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 40px;
}

// style override to reverse the order of toast notifications so newest is on the bottom
.snackbar-container {
    z-index: 999999 !important;
    flex-direction: column !important;
}
